import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import posthog from 'posthog-js';
import { AppModule } from './app/app.module';

posthog.init(process.env['POSTHOG_KEY']!, {
  api_host: process.env['POSTHOG_HOST'],
  person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
});

Sentry.init({
  dsn: 'https://d70b937b43b14e8a9aa05d6b977b4efa@o4505192617476096.ingest.sentry.io/4505192665907200',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
  // Tracing
  tracesSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  allowUrls: ['workerhero.com'],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  replaysOnErrorSampleRate: process.env['NODE_ENV'] === 'production' ? 0.1 : 1,
  environment: process.env['NODE_ENV'],
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
